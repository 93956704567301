import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Descriptions, Modal, Pagination, Table } from "antd";
import { setHeading, setselectedTab } from "../../../../Store/B2bslice";
import { getTableRummyRoundsList } from "../../../../ApiServices/Apifun";
import { ExtractDate, ExtractTime } from "../../../../Utils";

const RummyTableRounds = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [TableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const columns = [
    {
      title: "Winner Reason",
      dataIndex: "winner_reason",
      key: "winner_reason",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },

    {
      title: "Players",
      dataIndex: "players",
      key: "players",
      render: (players) => (
        <Button
          type="link"
          onClick={() => {
            setSelectedPlayers(players);
            setIsModalVisible(true);
          }}
        >
          View Players
        </Button>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <p className="w-[100px]">
          {ExtractDate(text)}.{ExtractTime(text)}
        </p>
      ),
    },
  ];
  const getTableRoundsData = () => {
    setLoading(true);
    getTableRummyRoundsList(`?page=${current}&limit=10`)
      .then((res) => {
        if (res.status) {
          setTableData(res.data);
          setCurrent(res.pagination.currentPage);
          setTotalPages(res.pagination.totalPages);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("11.1"));
    dispatch(setHeading("RummyTable Round Data"));
    getTableRoundsData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  return (
    <div className="mt-4">
      <Table
        className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
        columns={columns}
        loading={loading}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            className: "cursor-pointer text-[12px]",
          };
        }}
        dataSource={TableData}
      />
      <div className="mt-2 mb-8 flex justify-end items-center">
        <Pagination
          showQuickJumper={false}
          defaultCurrent={current}
          pageSize={10}
          showSizeChanger={false}
          current={current}
          total={10 * totalPages}
          onChange={(page, pageString) => setCurrent(page)}
        />
      </div>
      <div>
        <Modal
          title="Player Details"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key="close" onClick={() => setIsModalVisible(false)}>
              Close
            </Button>,
          ]}
        >
          {selectedPlayers.map((player) => (
            <Card
              key={player.user_id?._id}
              style={{ maxWidth: 1000, margin: "20px" }}
              className=" shadow-md shadow-primary border-0"
            >
              <Descriptions column={1} bordered>
                <Descriptions.Item label="User Name">
                  {player.user_id?.name || <p>Not provided</p>}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {player.user_id?.email || <p>Not provided</p>}
                </Descriptions.Item>
                <Descriptions.Item label="Starting Balance">
                  {player.opening_points }
                </Descriptions.Item>
                <Descriptions.Item label="Closing Balance">
                  {player.closing_points }
                </Descriptions.Item>
                <Descriptions.Item label="Result Amount">
                  {player.round_result_amount }
                </Descriptions.Item>
              </Descriptions>
            </Card>
          ))}
        </Modal>
      </div>
    </div>
  );
};

export default RummyTableRounds;
