import { lazy } from "react";
import ProtectedAuth from "./PrivateAuth";
import TeenPattiTable from "../Screens/Main/TeenPatti/Table";
import TeenPattiSettings from "../Screens/Main/TeenPatti/Settings/Settings";
import TableRounds from "../Screens/Main/TeenPatti/Table/TableRounds";
import RummyTableRounds from "../Screens/Main/Rummy/Table/TableRounds";
import RummyTable from "../Screens/Main/Rummy/Table";
import Tournaments from "../Screens/Main/Rummy/Table/Tournaments/Tournaments";
import RummySetting from "../Screens/Main/Rummy/Settings/RummySetting";
const LudoDetailsComponent = lazy(() =>
  import("../Screens/Main/Ludo/LudoDetails")
);
const LudoUserHistory = lazy(() =>
  import("../Screens/Main/Ludo/LudoUser/ludoUserHistory")
);
const ComissionSeting = lazy(() =>
  import("../Screens/Main/ComissionSetting/ComissionSeting")
);

const CommonRoomDetail = lazy(() =>
  import("../Screens/Main/GameList/CommonRoomDetails")
);
const WithdrawalRequest = lazy(() =>
  import("../Screens/Main/WithdrawalRequest/WithdrawalRequest")
);
const WalletTxn = lazy(() => import("../Screens/Main/UserList/WalletTxn"));
const UserList = lazy(() => import("../Screens/Main/UserList/UserList"));
const UserBettings = lazy(() =>
  import("../Screens/Main/UserList/UserBettings")
);
const ChangePassword = lazy(() =>
  import("../Screens/Main/Setting/ChangePassword")
);
const PAymentSetting = lazy(() =>
  import("../Screens/Main/PaymentSetting/PaymentSetting")
);
const RoomDetail = lazy(() => import("../Screens/Main/GameList/RoomDetail"));
const GameList = lazy(() => import("../Screens/Main/GameList/GameList"));
const CurrentRoomStats = lazy(() =>
  import("../Screens/Main/GameList/CurrentRoomStats")
);
const FundRequest = lazy(() =>
  import("../Screens/Main/FundRequest/FundRequest")
);
const Dashboard = lazy(() => import("../Screens/Main/Dashboard/Dashboard"));
const Comission = lazy(() => import("../Screens/Main/Comission/Comission"));
const AviatorSettings = lazy(() => import("../Screens/Main/Aviator/Settings"));
const AviatorRooms = lazy(() => import("../Screens/Main/Aviator/Rooms"));
const AviatorCurrentRoomstats = lazy(() =>
  import("../Screens/Main/Aviator/CurrentRoomstats")
);
const AviatorBetDetails = lazy(() =>
  import("../Screens/Main/Aviator/BetDetails")
);
const MinesSetting = lazy(() => import("../Screens/Main/Mines/MinesSetting"));
const MineBets = lazy(() => import("../Screens/Main/Mines/MineBets"));
const PlinkoSettings = lazy(() =>
  import("../Screens/Main/Plinko/PlinkoSettings")
);
const PlinkoBets = lazy(() => import("../Screens/Main/Plinko/PlinkoBets"));

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/game-list",
          element: <GameList />,
        },
        {
          path: "/aviator/settings",
          element: <AviatorSettings />,
        },
        {
          path: "/aviator/rooms",
          element: <AviatorRooms />,
        },
        {
          path: "/aviator/betDetail",
          element: <AviatorBetDetails />,
        },
        {
          path: "/aviator/current-room-stats",
          element: <AviatorCurrentRoomstats />,
        },
        {
          path: "/mines/settings",
          element: <MinesSetting />,
        },
        {
          path: "/mines/bets",
          element: <MineBets />,
        },
        {
          path: "/plinko/settings",
          element: <PlinkoSettings />,
        },
        {
          path: "/plinko/bets",
          element: <PlinkoBets />,
        },
        {
          path: "/user-list",
          element: <UserList />,
        },
        {
          path: "/bet-detail",
          element: <CommonRoomDetail />,
        },
        {
          path: "/payment-setting",
          element: <PAymentSetting />,
        },
        {
          path: "/fund-request",
          element: <FundRequest />,
        },
        {
          path: "/fund-request/:id",
          element: <FundRequest />,
        },
        {
          path: "/withdrawal-request",
          element: <WithdrawalRequest />,
        },
        {
          path: "/withdrawal-request/:id",
          element: <WithdrawalRequest />,
        },
        {
          path: "/current-room-stats",
          element: <CurrentRoomStats />,
        },
        {
          path: "/room-detail",
          element: <RoomDetail />,
        },
        {
          path: "/WalletTxn",
          element: <WalletTxn />,
        },
        {
          path: "/user-bettings",
          element: <UserBettings />,
        },
        {
          path: "/comission",
          element: <Comission />,
        },
        {
          path: "/comission-setting",
          element: <ComissionSeting />,
        },
        {
          path: "/teen_patti/tableData",
          element: <TeenPattiTable />,
        },
        {
          path: "/teen_patti/tableData/table_rounds",
          element: <TableRounds />,
        },
        {
          path: "/teen_patti/settings",
          element: <TeenPattiSettings />,
        },

        {
          path: "/rummy/tableData",
          element: <RummyTable />,
        },
        {
          path: "/rummy/tableData/table_rounds",
          element: <RummyTableRounds />,
        },
        {
          path: "/rummy/settings",
          element: <RummySetting />,
        },
        {
          path: "/rummy/tournaments",
          element: <Tournaments />,
        },

        //Ludo_Routes
        {
          path: "/ludo_admin/user_history",
          element: <LudoUserHistory />,
        },
        {
          path: "/ludo_admin/ludo_details",
          element: <LudoDetailsComponent />,
        },
        //Ludo_Routes
        {
          path: "/change-password",
          element: <ChangePassword />,
        },
        {
          path: "/*",
          element: <Dashboard />,
        },
      ],
    },
  ];
};
