import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {
  selectedTab: "",
  heading: "",
  walletBal:""
};

export const roulletSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setselectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setHeading: (state, action) => {
      state.heading = action.payload;
    },
    setWalletBal: (state, action) => {
      state.walletBal = action.payload;
    },
    clearAllRedux: (state) => {
      state.selectedTab = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setselectedTab, setHeading,setWalletBal, clearAllRedux } =
  roulletSlice.actions;

export default roulletSlice.reducer;
