import {
  Delete,
  FormDataPost,
  Get,
  Patch,
  Post,
  Put,
  getAPIUrl,
} from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};

export const userLogout = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.logout, param);
  return Post(url, data, false);
};
export const createGames = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.createGame, param);
  return Post(url, data);
};
export const getDashboardData = (param = "") => {
  const url = getAPIUrl(Globalendpoints.dashboardCount, param);
  return Get(url);
};
export const getGraphData = (param = "") => {
  const url = getAPIUrl(Globalendpoints.graphData, param);
  return Get(url);
};
export const getGames = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getGameList, param);
  return Get(url);
};
export const updateGame = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateGame, param);
  return Put(url, data);
};
export const addBank = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addBankDetail, param);
  return FormDataPost(url, data);
};
export const updateBank = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateBankDetail, param);
  return Put(url, data);
};
export const fetchBank = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchBankDetail, param);
  return Get(url);
};
export const fetchFundReq = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchFundReq, param);
  return Get(url);
};
export const fundReqAction = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.fundReqAction, param);
  return Put(url, data);
};
export const getWithdrawalReq = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchWithdrawalReq, param);
  return Get(url);
};
export const updateWithdrawalReq = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateWithdrawalReq, param);
  return Put(url, data);
};

export const GetCurrentRoomStats = (param = "") => {
  const url = getAPIUrl(Globalendpoints.currentRoomStats, param);
  return Get(url);
};
export const GetRoomDetails = (param = "") => {
  const url = getAPIUrl(Globalendpoints.roomDetails, param);
  return Get(url);
};
export const ChooseWinner = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.chooseWinner, param);
  return Post(url, data);
};

export const GetUserList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.userList, param);
  return Get(url);
};

export const UpdateUser = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateUser, param);
  return Patch(url, data);
};
export const GetUserBettings = (param = "") => {
  const url = getAPIUrl(Globalendpoints.userBettings, param);
  return Get(url);
};
export const GetUserWalletTxn = (param = "") => {
  const url = getAPIUrl(Globalendpoints.walletTxn, param);
  return Get(url);
};

export const GetPaymentSetting = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getPaymentSetting, param);
  return Get(url);
};

export const updatePaymentSetting = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updatePaymentSetting, param);
  return Put(url, data);
};
export const colorOption = (param = "") => {
  const url = getAPIUrl(Globalendpoints.colorOption, param);
  return Get(url);
};
export const getBetDetailById = (param = "") => {
  const url = getAPIUrl(Globalendpoints.betDetailById, param);
  return Get(url);
};

export const passwordChange = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.changePassword, param);
  return Put(url, data);
};

// aviator apis

export const getAviatorSetting = (param = "") => {
  const url = getAPIUrl(Globalendpoints.aviatorSetting, param);
  return Get(url);
};
export const GetAviatorPlan = (param = "") => {
  const url = getAPIUrl(Globalendpoints.aviatorPlanHandler, param);
  return Get(url);
};
export const getAviatorRooms = (param = "") => {
  const url = getAPIUrl(Globalendpoints.aviatorRooms, param);
  return Get(url);
};
export const getAviatorBettingRecors = (param = "") => {
  const url = getAPIUrl(Globalendpoints.aviatorBettingRecord, param);
  return Get(url);
};
export const setAviatorRange = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.aviatorSetRange, param);
  return Post(url, data);
};
export const CrashPlane = (param = "") => {
  const url = getAPIUrl(Globalendpoints.aviatorCrashPlane, param);
  return Post(url);
};
export const getAviatorCurrentPlane = (param = "") => {
  const url = getAPIUrl(Globalendpoints.aviatorCurrentPlane, param);
  return Get(url);
};

// mines apis
export const getMinesSettings = (param = "") => {
  const url = getAPIUrl(Globalendpoints.mineSettings, param);
  return Get(url);
};
export const getMinesBettingRecords = (param = "") => {
  const url = getAPIUrl(Globalendpoints.minesBettingRecord, param);
  return Get(url);
};
export const getMinesLatestBettingRecords = (param = "") => {
  const url = getAPIUrl(Globalendpoints.minesLatestBettingRecord, param);
  return Get(url);
};
export const updateMinesSettings = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.minesUpdateSetting, param);
  return Post(url, data);
};

// plinko apis
export const getPlinkoSettings = (param = "") => {
  const url = getAPIUrl(Globalendpoints.plinkoSettings, param);
  return Get(url);
};
export const getPlinkoBettingRecords = (param = "") => {
  const url = getAPIUrl(Globalendpoints.plinkoBettingRecord, param);
  return Get(url);
};
export const getPlinkoLatestBettingRecords = (param = "") => {
  const url = getAPIUrl(Globalendpoints.plinkoLatesyBettingRecord, param);
  return Get(url);
};

// COMISSION SETTING

export const getCommisionSetting = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchComissionSetting, param);
  return Get(url);
};

export const updateCommisionSetting = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateReferalComm, param);
  return Put(url, data);
};
export const updatePlinkoSettings = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.plinkoUpdateSetting, param);
  return Post(url, data);
};

//TeenPAtti
export const getTeenPattiUserList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getTeenPattiUserList, param);
  return Get(url);
};
export const modifyStatus = (param = "") => {
  const url = getAPIUrl(Globalendpoints.modifyStatus, param);
  return Put(url);
};
export const getTeenPattiTableList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getTeenPattiTableList, param);
  return Get(url);
};
export const getRummyTableList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getRummyTableList, param);
  return Get(url);
};
export const getTableRoundsList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getTableRoundsList, param);
  return Get(url);
};
export const getTableRummyRoundsList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getTableRummyRoundsList, param);
  return Get(url);
};
export const getTournamentsList = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.getTournamentsList, param);
  return Post(url, data);
};

export const getRummySettingsList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getRummySettingsList, param);
  return Get(url);
};

export const rummyDeleteSettings = (param = "") => {
  const url = getAPIUrl(Globalendpoints.rummyDeleteSettings, param);
  return Delete(url, param);
};

export const rummyDeleteTournaments = (param = "") => {
  const url = getAPIUrl(Globalendpoints.rummyDeleteTournaments, param);
  return Put(url, param);
};

export const getTeenPattiSettingsList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getTeenPattiSettingsList, param);
  return Get(url);
};
export const deleteSettings = (param = "") => {
  const url = getAPIUrl(Globalendpoints.deleteSettings, param);
  return Delete(url, param);
};
export const settings_edit = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.settings_edit, param);
  return Put(url, data);
};

export const Rummysettings_edit = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.Rummysettings_edit, param);
  return Put(url, data);
};

// export const Tournamentsettings_edit = (data,param = "") => {
//   const url = getAPIUrl(Globalendpoints.Tournamentsettings_edit, param);
//   return Put(url,data);
// };
export const Tournamentsettings_edit = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.Tournamentsettings_edit, param);
  return Post(url, data);
};

export const RummyaddSettings = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.RummyaddSettings, param);
  return Post(url, data);
};

export const tournamentsaddSettings = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.tournamentsaddSettings, param);
  return Post(url, data);
};

export const addSettings = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addSettings, param);
  return Post(url, data);
};
export const getTeenPattiUserRechargeHistoryList = (param = "") => {
  const url = getAPIUrl(
    Globalendpoints.getTeenPattiUserRechargeHistoryList,
    param
  );
  return Get(url);
};
//Ludo Api Function

export const getLudoUserHistory = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getLudoUserHistory, param);
  return Get(url);
};
export const getLudoDetails = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getLudoDetails, param);
  return Get(url);
};
export const ludo_edit = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.ludo_edit, param);
  return Post(url, data);
};
