import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  message,
  Pagination,
  Popconfirm,
  Table,
} from "antd";
import { setHeading, setselectedTab } from "../../../../Store/B2bslice";
import {  MdDeleteOutline } from "react-icons/md";
import {
  deleteSettings,
  getTeenPattiSettingsList,
} from "../../../../ApiServices/Apifun";
import { MdOutlineEdit } from "react-icons/md";
import { messageConfiguration } from "../../../../Utils";
import AddSettings from "./AddSettings";

const TeenPattiSettings = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [settingsData, setSettingsData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [from, setFrom] = useState("add");
  const [filldata, setFilldata] = useState({});

  const DeleteSettings = (id) => {
    deleteSettings(id)
      .then((response) => {
        if (response.status) {
          message.open(messageConfiguration("success", response.message, 3));
          getSettingsData(current);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const columns = [
    {
      title: "Max. Blind",
      dataIndex: "max_blind",
      key: "max_blind",
      render: (text) => <p className="w-[100px] m-auto">{text}</p>,
    },

    {
      title: "Max. players",
      dataIndex: "max_players",
      key: "max_players",
      render: (text) => <p className="w-[100px] m-auto">{text}</p>,
    },
    {
      title: "Boot Amount",
      dataIndex: "boot_amount",
      key: "boot_amount",
      render: (text) => <p className="w-[100px] m-auto">{text} Rs.</p>,
    },
    {
      title: "Max. Plot Limit",
      dataIndex: "max_pot_limit",
      key: "max_pot_limit",
      render: (text) => <p className="w-[100px] m-auto">{text} Rs.</p>,
    },
    {
      title: "Max. Bet Amount",
      dataIndex: "max_bet_amount",
      key: "max_bet_amount",
      render: (text) => <p className="w-[100px] m-auto">{text} Rs.</p>,
    },
    {
      title: "Edit",
      dataIndex: "Edit",
      key: "Edit",
      render: (text, record) => (
        <p
          className="text-lightwhite m-auto text-center font-bold cursor-pointer text-lg"
          onClick={() => {
            setIsModalVisible(true);
            setFrom("edit");
            setFilldata(record);
          }}
        >
          <MdOutlineEdit />
        </p>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (text, record) => (
        <Popconfirm
          okType="danger"
          title="Are you sure to delete this record?"
          onConfirm={() => DeleteSettings(record._id)}
          okText="Delete"
          cancelText="No"
        >
          <p className="text-lightwhite m-auto text-center font-bold cursor-pointer text-lg">
            <MdDeleteOutline />
          </p>
        </Popconfirm>
      ),
    },
  ];
  const getSettingsData = () => {
    setLoading(true);
    getTeenPattiSettingsList(`?page=${current}&limit=10`)
      .then((res) => {
        if (res.status) {
          setSettingsData(res.Data);
          setCurrent(res.pagination.currentPage);
          setTotalPages(res.pagination.TotalRecords);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("8.3"));
    dispatch(setHeading("Teen Patti Settings Data"));
    getSettingsData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <div className="mt-4">
      <div className="flex justify-end items-center m-4">
        <Button
          className=" mt-4 hover:border-none focus:border-none bg-primaryhover"
          onClick={() => {
            setFrom("add");
            setIsModalVisible(true);
            // setFilldata();
          }}
        >
          Add Settings
        </Button>
      </div>
      <Table
        className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
        columns={columns}
        loading={loading}
        pagination={false}
        rowClassName={(record, index) =>
          index % 2 === 0
            ? "even-row border-none cursor-pointer"
            : "odd-row border-none cursor-pointer"
        }
        onRow={(record, rowIndex) => {
          return {
            className: "cursor-pointer text-[12px]",
          };
        }}
        dataSource={settingsData}
      />
      <div className="mt-2 mb-8 flex justify-end items-center">
        <Pagination
          showQuickJumper={false}
          defaultCurrent={current}
          pageSize={10}
          showSizeChanger={false}
          current={current}
          total={totalPages}
          onChange={(page, pageString) => setCurrent(page)}
        />
      </div>
      <div>
        <div>
          {setIsModalVisible && (
            <AddSettings
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              filldata={filldata}
              setFill={setFilldata}
              from={from}
              apiCall={getSettingsData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TeenPattiSettings;
