import React, { useEffect, useRef } from "react";
import { message, Form, Modal, Input } from "antd";
import { addSettings, settings_edit } from "../../../../ApiServices/Apifun";

const AddSettings = ({
  isModalVisible,
  setIsModalVisible,
  from,
  apiCall,
  filldata,
  setFill,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(form);

  useEffect(() => {
    if (from === "edit" && filldata) {
      form.setFieldsValue({
        max_blind: filldata.max_blind,
        max_players: filldata.max_players,
        boot_amount: filldata.boot_amount,
        max_bet_amount: filldata.max_bet_amount,
        max_pot_limit: filldata.max_pot_limit,
      });
    } else {
      form.resetFields();
    }
  }, [from, filldata, form]);

  const createSettings = (values) => {
    const payload = {
      max_blind: values.max_blind,
      max_players: values.max_players,
      boot_amount: values.boot_amount,
      max_bet_amount: values.max_bet_amount,
      max_pot_limit: values.max_pot_limit,
    };
    addSettings(payload)
      .then((response) => {
        if (response.status) {
          message.success({ content: response.message });
          apiCall();
          setIsModalVisible(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editSettings = (values) => {
    const payload = {
      max_blind: values.max_blind,
      max_players: values.max_players,
      boot_amount: values.boot_amount,
      max_bet_amount: values.max_bet_amount,
      max_pot_limit: values.max_pot_limit,
    };
    settings_edit(payload, `${filldata._id}`)
      .then((response) => {
        if (response.status) {
          message.success({ content: response.message });
          apiCall();
          setIsModalVisible(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        from === "add" ? createSettings(values) : editSettings(values);

        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      okButtonProps={{
        className: " mt-4 hover:border-none focus:border-none bg-primaryhover",
      }}
      title={from === "add" ? "Add Settings " : "Edit Settings"}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      onOk={handleOk}
    >
      <Form
        className=" p-6"
        layout="vertical"
        form={form}
        ref={formRef}
        // initialValues={
        //   from === "add"
        //     ? {}
        //     : {
        //         max_blind: filldata?.max_blind || undefined,
        //         max_players: filldata?.max_players || undefined,
        //         boot_amount: filldata?.boot_amount || undefined,
        //         max_bet_amount: filldata?.max_bet_amount || undefined,
        //         max_pot_limit: filldata?.max_pot_limit || undefined,
        //       }
        // }
      >
        <Form.Item
          label="Max Blind"
          name="max_blind"
          rules={[{ required: true, message: "Please enter Max Blind" }]}
        >
          <Input
            onInput={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              e.target.value = value;
            }}
            min={1}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Max Players"
          name="max_players"
          rules={[{ required: true, message: "Please enter Max Players" }]}
        >
          <Input
            onInput={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              e.target.value = value;
            }}
            min={1}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Boot Amount"
          name="boot_amount"
          rules={[{ required: true, message: "Please enter Boot Amount" }]}
        >
          <Input
            onInput={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              e.target.value = value;
            }}
            min={1}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Max Bet Amount"
          name="max_bet_amount"
          rules={[{ required: true, message: "Please enter Max Bet Amount" }]}
        >
          <Input
            onInput={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              e.target.value = value;
            }}
            min={1}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Max Pot Limit"
          name="max_pot_limit"
          rules={[{ required: true, message: "Please enter Max Pot Limit" }]}
        >
          <Input
            onInput={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              e.target.value = value;
            }}
            min={1}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSettings;
