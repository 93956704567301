import React, { useEffect, useState } from "react";
import {
  message,
  Button,
  Form,
  Modal,
  InputNumber,
  Select,
  Row,
  Col,
  Checkbox,
  Input,
  DatePicker,
} from "antd";
import {
  tournamentsaddSettings,
  Tournamentsettings_edit,
} from "../../../../../ApiServices/Apifun";
import { Option } from "antd/es/mentions";
import moment from "moment";

const TournamentsAddSettings = ({
  isModalVisible,
  setIsModalVisible,
  from,
  apiCall,
  filldata,
  setFill,
}) => {
  const [form] = Form.useForm();
  const { tableType, table_properties, ...rest } = filldata;
  const [tableTypes, setTableType] = useState(""); // For controlling table type
  const [prizeDistribution, setPrizeDistribution] = useState([
    { range: [1, 1], prizeMoney: 500, prizeDivideOrNot: false },
  ]);
  const [formFields, setFormFields] = useState([]);

  // Dropdown options for table type
  const tableOptions = [
    { value: "Point", label: "Point" },
    { value: "Deal", label: "Deal" },
    { value: "Pool", label: "Pool" },
  ];

  // Handle prize distribution updates
  const handlePrizeChange = (index, key, value) => {
    const updatedPrizeDistribution = [...prizeDistribution];
    if (key === "range") {
      updatedPrizeDistribution[index].range = value;
    } else {
      updatedPrizeDistribution[index][key] = value;
    }
    setPrizeDistribution(updatedPrizeDistribution);
  };

  // Add a new prize distribution object
  const addPrizeDistribution = () => {
    setPrizeDistribution([
      ...prizeDistribution,
      { range: [1, 1], prizeMoney: 0, prizeDivideOrNot: false },
    ]);
  };

  useEffect(() => {
    if (from === "edit" && filldata) {
      const {
        tableType,
        table_properties,
        start_date_time,
        max_register_time,
        ...rest
      } = filldata;

      const keyMapping = {
        max_players: "maxPlayers",
        min_players: "minPlayers",
        no_of_players: "noOfPlayers",
        is_joinable: "isJoinable",
        table_type: "tableType",
        start_date_time: "startingDateTime",
        max_register_time: "maxRegisterTime",
        winner_amount: "winnerAmount",
        joining_amount: "joiningAmount",
        player_per_table: "playersOnTable",
        no_of_rounds: "noOfRounds",
        total_points: "totalPoints",
      };

      const mappedData = Object.keys(rest).reduce((acc, key) => {
        const mappedKey = keyMapping[key] || key;
        acc[mappedKey] = rest[key];
        return acc;
      }, {});

      const dynamicFields = table_properties
        ? Object.keys(table_properties).reduce((acc, key) => {
            acc[
              `${
                filldata.table_type == "Pool"
                  ? "totalPoints"
                  : filldata.table_type == "Deal"
                  ? "noOfRounds"
                  : "pointValue"
              }`
            ] = table_properties[key];
            return acc;
          }, {})
        : {};

      const formattedDates = {
        startingDateTime: start_date_time ? moment(start_date_time) : null,
        maxRegisterTime: max_register_time ? moment(max_register_time) : null,
      };

      const formData = {
        tableType,
        ...mappedData,
        ...dynamicFields,
        ...formattedDates,
      };

      form.setFieldsValue(formData);
      // Trigger dynamic field rendering
      if (formData.tableType) {
        handleTableTypeChange(formData.tableType);
      }
    }
    from === "add" && form.resetFields();
  }, [from, filldata, form]);

  const createSettings = (values) => {
    const payload = {
      name: values.name,
      playersOnTable: values.playersOnTable,
      winnerAmount: values.winnerAmount,
      tableType: values.tableType,
      maxPlayers: values.maxPlayers,
      joiningAmount: values.joiningAmount,
      prizeDistributionObj: prizeDistribution,
      prizeDistributionByAdmin: values.prizeDistributionByAdmin,
      startingDateTime: values.startingDateTime.valueOf(), // Convert to timestamp
      maxRegisterTime: values.maxRegisterTime.valueOf(),
    };

    switch (values.tableType) {
      case "Point":
        payload.tableProperties = {
          pointValue: values.pointValue, // Dynamic field for Point
        };
        break;

      case "Deal":
        payload.tableProperties = {
          noOfRounds: values.noOfRounds, // Dynamic field for Deal
        };
        break;

      case "Pool":
        payload.tableProperties = {
          totalPoints: values.totalPoints, // Dynamic field for Pool
        };
        break;

      default:
        console.error("Unknown table type");
        return; // Exit if table type is unknown
    }

    // Replace this with your API call

    // Call the API with the constructed payload
    tournamentsaddSettings(payload)
      .then((response) => {
        if (response.status) {
          message.success({ content: response.message });
          apiCall(); // Refresh the data
          setIsModalVisible(false); // Close the modal
          form.setFieldValue(null);
        }
      })
      .catch((error) => {
        console.error("Error submitting settings:", error);
      });
  };

  const editSettings = (values) => {
    const payload = {
      name: values.name,
      playersOnTable: values.playersOnTable,
      winnerAmount: values.winnerAmount,
      tableType: values.tableType,
      maxPlayers: values.maxPlayers,
      tournamentId: filldata._id,
      joiningAmount: values.joiningAmount,
      // prizeDistributionObj: prizeDistribution,
      prizeDistributionByAdmin: values.prizeDistributionByAdmin,
      startingDateTime: values.startingDateTime.valueOf(), // Convert to timestamp
      maxRegisterTime: values.maxRegisterTime.valueOf(),
    };
    switch (values.tableType) {
      case "Point":
        payload.tableProperties = {
          pointValue: values.pointValue, // Dynamic field for Point
        };
        break;

      case "Deal":
        payload.tableProperties = {
          noOfRounds: values.noOfRounds, // Dynamic field for Deal
        };
        break;

      case "Pool":
        payload.tableProperties = {
          totalPoints: values.totalPoints, // Dynamic field for Pool
        };
        break;

      default:
        console.error("Unknown table type");
        return; // Exit if table type is unknown
    }
    // const payload = {
    //     ...values,

    //     startingDateTime: values.startingDateTime.valueOf(), // Convert to timestamp
    //     maxRegisterTime: values.maxRegisterTime.valueOf(),
    //     tournamentId: filldata._id,
    //     tableProperties: {
    //         pointValue: values.pointValue || 0.1,
    //     },
    // };

    // Make API call
    Tournamentsettings_edit(payload)
      .then((response) => {
        if (response.status) {
          message.success({ content: response.message });
          apiCall(); // Refresh data
          setIsModalVisible(false); // Close modal
        }
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };

  // Handle table type selection
  const handleTableTypeChange = (tableType) => {
    let dynamicFields = [];
    // Common Joining Amount Field
    const joiningAmountField = {
      name: "joiningAmount",
      label: "Joining Amount",
      type: "number",
      rules: [
        { required: true, message: "Please enter Joining Amount" },
        {
          validator(_, value) {
            if (value && value <= 10) {
              return Promise.reject(
                new Error("Joining Amount must be greater than 10")
              );
            }
            return Promise.resolve();
          },
        },
      ],
    };

    // Define dynamic fields based on table type
    switch (tableType) {
      case "Deal":
        dynamicFields = [
          joiningAmountField,
          {
            name: "noOfRounds",
            label: "Number of Rounds",
            type: "dropdown",
            options: [2, 3],
          },
        ];
        break;

      case "Point":
        dynamicFields = [
          joiningAmountField,
          {
            name: "pointValue",
            label: "Point Value",
            type: "number",
            rules: [
              { required: true, message: "Please enter Joining Amount" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value > 5) {
                    return Promise.reject(
                      new Error("Joining Amount must be smaller than 5")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
        ];
        break;

      case "Pool":
        dynamicFields = [
          joiningAmountField,
          {
            name: "totalPoints",
            label: "Total Points",
            type: "dropdown",
            options: [101, 201],
          },
        ];
        break;

      default:
        dynamicFields = []; // No fields for undefined table type
    }

    // Update form fields dynamically
    setFormFields(dynamicFields);

    // Reset and set form values
    // form.resetFields();
    setTableType(tableType);
    // form.setFieldsValue({
    //     tableType: tableType, // Set table_type
    //     playersOnTable: tableType === "Pool" ? undefined : 2, // Default value for Point/Deal
    // });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        from === "add" ? createSettings(values) : editSettings(values);

        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title={from === "add" ? "Add Tournaments" : "Edit Tournaments"}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
    <div className="h-[500px] overflow-y-scroll">
    <Form form={form} layout="vertical" onFinish={handleOk}>
        {/* Table Type Dropdown */}
        <Form.Item
          label="Table Type"
          name="tableType"
          rules={[{ required: true, message: "Please select a table type" }]}
        >
          <Select
            placeholder="Select Table Type"
            onChange={handleTableTypeChange}
          >
            {tableOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Max. Players"
          name="maxPlayers"
          rules={[
            { required: true, message: "Please enter number of players" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value < 100) {
                  return Promise.reject(
                    new Error("players  must be greater than or equal to 100")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Starting Date and Time"
          name="maxRegisterTime"
          rules={[
            {
              required: true,
              message: "Please select Max Register Date and Time",
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            style={{ width: "100%" }}
            onChange={(value) => {
              form.setFieldsValue({ maxRegisterTime: value }); // Set the value directly
            }}
          />
        </Form.Item>
        <Form.Item
          label="Max Register Date and Time"
          name="startingDateTime"
          rules={[
            { required: true, message: "Please select Starting Date and Time" },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            style={{ width: "100%" }}
            onChange={(value) => {
              form.setFieldsValue({ startingDateTime: value }); // Set the value directly
            }}
          />
        </Form.Item>

        {formFields.map((field) => (
          <Form.Item
            key={field.name}
            label={field.label}
            name={field.name}
            rules={
              field.rules || [
                { required: true, message: `Please enter ${field.label}` },
              ]
            }
          >
            {field.type === "number" ? (
              tableTypes == "point" ? (
                <InputNumber min={1} max={5} style={{ width: "100%" }} />
              ) : (
                <InputNumber min={1} style={{ width: "100%" }} />
              )
            ) : field.type === "dropdown" ? (
              <Select placeholder={`Select ${field.label}`}>
                {field.options.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            ) : null}
          </Form.Item>
        ))}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter your name" }]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Winner Amount"
          name="winnerAmount"
          rules={[
            { required: true, message: "Please enter your winner Amount" },
          ]}
        >
          <Input min={2} max={2} style={{ width: "100%" }} />
        </Form.Item>

        {/* Players On Table */}
        <Form.Item
          label="Players On Table"
          name="playersOnTable"
          rules={[
            { required: true, message: "Please select Players On Table" },
          ]}
        >
          {tableTypes === "Pool" ? (
            <Select placeholder="Select Players">
              <Option value={2}>2</Option>
              <Option value={6}>6</Option>
            </Select>
          ) : (
            <InputNumber min={2} max={2} disabled style={{ width: "100%" }} />
          )}
        </Form.Item>
        <Form.Item
          label="Prize Distribution By Admin"
          name="prizeDistributionByAdmin"
          valuePropName="checked" // Ensures the checkbox value is handled correctly
        >
          <Checkbox
            onChange={(e) => {
              form.setFieldsValue({
                prizeDistributionByAdmin: e.target.checked,
              });
            }}
            className="text-white"
          >
            Prize Distribution By Admin?
          </Checkbox>
        </Form.Item>
        {from === "add" && (
          <>
            {/* Prize Distribution */}
            <div style={{ marginBottom: "20px" }}>
              <h4 className="text-white">Prize Distribution</h4>
              {prizeDistribution.map((prize, index) => (
                <div className="my-2">
                  <Row gutter={16} key={index} style={{ marginBottom: "10px" }}>
                    <Col span={8}>
                      <InputNumber
                        placeholder="Start Range"
                        value={prize.range[0]}
                        min={1}
                        onChange={(value) =>
                          handlePrizeChange(index, "range", [
                            value,
                            prize.range[1],
                          ])
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col span={8}>
                      <InputNumber
                        placeholder="End Range"
                        value={prize.range[1]}
                        min={prize.range[0]}
                        onChange={(value) =>
                          handlePrizeChange(index, "range", [
                            prize.range[0],
                            value,
                          ])
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col span={6}>
                      <InputNumber
                        placeholder="Prize Money"
                        value={prize.prizeMoney}
                        min={0}
                        onChange={(value) =>
                          handlePrizeChange(index, "prizeMoney", value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  <Col span={2}>
                    <Checkbox
                      checked={prize.prizeDivideOrNot}
                      onChange={(e) =>
                        handlePrizeChange(
                          index,
                          "prizeDivideOrNot",
                          e.target.checked
                        )
                      }
                      className="text-white"
                    >
                      Divide?
                    </Checkbox>
                  </Col>
                </div>
              ))}
              <Button
                type="dashed"
                onClick={addPrizeDistribution}
                style={{ width: "100%" }}
              >
                + Add Prize Distribution
              </Button>
            </div>
          </>
        )}

        {/* Submit Button */}
        <Form.Item>
          <Button htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
    </Modal>
  );
};

export default TournamentsAddSettings;
