import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Pagination, Popconfirm, Table, message } from "antd";
import { setHeading, setselectedTab } from "../../../../../Store/B2bslice";
import {
  getTournamentsList,
  rummyDeleteTournaments,
} from "../../../../../ApiServices/Apifun";
import {
  ExtractDate,
  ExtractTime,
  messageConfiguration,
} from "../../../../../Utils";
import { BsEye } from "react-icons/bs";
import TournamentsAddSettings from "./AddTournaments";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";

const Tournaments = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPostionModel, setisPostionModel] = useState(false);
  const [from, setFrom] = useState("add");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [TableData, setTableData] = useState([]);
  const [PostionData, setPostionData] = useState([]);
  const [filldata, setFilldata] = useState({});

  const DeleteSettings = (id) => {
    rummyDeleteTournaments(id)
      .then((response) => {
        if (response.status) {
          message.open(messageConfiguration("success", response.message, 3));
          getTournamentsList();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const columns = [
    {
      title: "Player name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
    {
      title: "Number of players",
      dataIndex: "no_of_players",
      key: "no_of_players",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
    {
      title: "Players on table",
      dataIndex: "player_per_table",
      key: "player_per_table",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },

    {
      title: "Max. players",
      dataIndex: "max_players",
      key: "max_players",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
    {
      title: "Min. players",
      dataIndex: "min_players",
      key: "min_players",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
    {
      title: "Joining Amount",
      dataIndex: "joining_amount",
      key: "joining_amount",
      render: (text) => <p className="w-[100px]">{text} Rs.</p>,
    },
    // {
    //   title: "Max. Plot Limit",
    //   dataIndex: "max_pot_limit",
    //   key: "max_pot_limit",
    //   render: (text) => <p className="w-[100px]">{text} Rs.</p>,
    // },

    {
      title: "Table type",
      dataIndex: "table_type",
      key: "table_type",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },

    {
      title: "Winner Declared",
      dataIndex: "is_winner_declared",
      key: "is_winner_declared",
      render: (text) => <p className="w-[100px]">{text ? "Yes" : "No"}</p>,
    },
    {
      title: "Winner amount",
      dataIndex: "winner_amount",
      key: "winner_amount",
      render: (text) => <p className="w-[100px]">{text} Rs.</p>,
    },
    {
      title: "Status ",
      dataIndex: "is_joinable",
      key: "is_joinable",
      render: (text, record) => (
        <p className="w-[100px] bg-primary text-white rounded-md">
          {record.is_joinable === true ? "Joinable" : "Not Joinable"}
        </p>
      ),
    },
    {
      title: "Tournament Register Time",
      dataIndex: "startRegisterTime",
      key: "startRegisterTime",
      render: (text) => (
        <p className="w-[100px]">
          {ExtractDate(text)}.{ExtractTime(text)}
        </p>
      ),
    },

    {
      title: "Match start date",
      dataIndex: "start_date_time",
      key: "start_date_time",
      render: (text) => (
        <p className="w-[100px]">
          {ExtractDate(text)}.{ExtractTime(text)}
        </p>
      ),
    },
    {
      title: "Max Register Time",
      dataIndex: "max_register_time",
      key: "max_register_time",
      render: (text) => (
        <p className="w-[100px]">
          {ExtractDate(text)}.{ExtractTime(text)}
        </p>
      ),
    },
    {
      title: "Edit",
      dataIndex: "Edit",
      key: "Edit",
      render: (text, record) => (
        <p
          className="text-primary font-bold cursor-pointer text-lg"
          onClick={() => {
            setIsModalVisible(true);
            setFrom("edit");
            setFilldata(record);
          }}
        >
          <MdOutlineEdit />
        </p>
      ),
    },
    {
      title: "View Player Postions",
      dataIndex: "players_position",
      key: "players_position",
      render: (text, record) => (
        <p
          className="text-primary font-bold cursor-pointer text-lg"
          onClick={() => {
            setisPostionModel(true);
            setPostionData(text);
          }}
        >
          <BsEye />
        </p>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (text, record) => (
        <Popconfirm
          okType="danger"
          title="Are you sure to delete this record?"
          onConfirm={() => DeleteSettings(record._id)}
          okText="Delete"
          cancelText="No"
        >
          <p className="text-primary font-bold cursor-pointer text-lg">
            <MdDeleteOutline />
          </p>
        </Popconfirm>
      ),
    },
  ];

  const playerPostion = [
    {
      title: "Player name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
    {
      title: "Postion",
      dataIndex: "position",
      key: "position",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
  ];

  const getTableData = () => {
    setLoading(true);
    const payload = {
      sortIn: "asc",
      // sortBy:""
    };
    getTournamentsList(payload)
      .then((res) => {
        if (res.status) {
          setTableData(res.Data.tournamentList);
          setCurrent(res.pagination.currentPage);
          setTotalPages(res.pagination.totalPages);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("11.3"));
    dispatch(setHeading("Tournaments Table Data"));
    getTableData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  return (
    <div className="mt-4">
      <div className="flex justify-end items-center m-4">
        <Button
          className=" mt-4 hover:border-none focus:border-none bg-primaryhover"
          onClick={() => {
            setFrom("add");
            setIsModalVisible(true);
            // setFilldata();
          }}
        >
          Add Tournaments
        </Button>
      </div>
      <Table
        className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
        columns={columns}
        loading={loading}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            className: "cursor-pointer text-[12px]",
          };
        }}
        dataSource={TableData}
      />
      <div className="mt-2 mb-8 flex justify-end items-center">
        <Pagination
          showQuickJumper={false}
          defaultCurrent={current}
          pageSize={10}
          showSizeChanger={false}
          current={current}
          total={10 * totalPages}
          onChange={(page, pageString) => setCurrent(page)}
        />
      </div>
      <div>
        <div>
          {setIsModalVisible && (
            <TournamentsAddSettings
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              filldata={filldata}
              setFill={setFilldata}
              from={from}
              apiCall={getTableData}
            />
          )}
        </div>
        <div>
          {isPostionModel && (
            <Modal
              title={"Player positions"}
              visible={isPostionModel}
              onCancel={() => setisPostionModel(false)}
              footer={null}
            >
              <Table
                className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
                columns={playerPostion}
                loading={loading}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    className: "cursor-pointer text-[12px]",
                  };
                }}
                dataSource={PostionData}
              />
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tournaments;
