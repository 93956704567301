import React, { useEffect, useState } from "react";
import { message, Form, Modal, InputNumber, Select } from "antd";
import { RummyaddSettings, Rummysettings_edit } from "../../../../ApiServices/Apifun";
import { Option } from "antd/es/mentions";

const RummyAddSettings = ({
  isModalVisible,
  setIsModalVisible,
  from,
  apiCall,
  filldata,
  setFill,
}) => {
  const [form] = Form.useForm();

  const { table_type, table_properties, ...rest } = filldata;
  useEffect(() => {
    if (from === "edit" && filldata) {
      const { table_type, table_properties, ...rest } = filldata;
      // Trigger handleTableTypeChange to set dynamic fields
      handleTableTypeChange(table_type);

      // Extract dynamic keys from table_properties
      const dynamicFields = table_properties
        ? Object.keys(table_properties).reduce((acc, key) => {
          acc[key] = table_properties[key];
          return acc;
        }, {})
        : {};

      // Combine static and dynamic fields
      const formData = { table_type, ...rest, ...dynamicFields };

      // Set form fields
      form.setFieldsValue(formData);
    }
    //eslint-disable-next-line
  }, [from, filldata, form]);




  const createSettings = (values) => {

    // Start with a base payload structure
    const payload = {
      table_type: values.table_type, // Selected table type
    };

    // Dynamically build the payload based on table_type
    switch (values.table_type) {
      case "Point":
        payload.table_properties = {
          point_value: values.point_value, // Dynamic field for Point
        };
        break;

      case "Deal":
        payload.joining_amount = values.joining_amount;
        payload.table_properties = {
          no_of_rounds: values.no_of_rounds, // Dynamic field for Deal
        };
        break;

      case "Pool":
        payload.joining_amount = values.joining_amount;
        payload.max_players = values.max_players; // Extra field for Pool
        payload.table_properties = {
          total_points: values.total_points, // Dynamic field for Pool
        };
        break;

      default:
        console.error("Unknown table type");
        return; // Exit if table type is unknown
    }


    // Call the API with the constructed payload
    RummyaddSettings(payload)
      .then((response) => {
        if (response.status) {
          message.success({ content: response.message });
          apiCall(); // Refresh the data
          setIsModalVisible(false); // Close the modal
        }
      })
      .catch((error) => {
        console.error("Error submitting settings:", error);
      });
  };


  const editSettings = (values) => {
    const { table_type, ...rest } = values;
    // Dynamically construct table_properties from the remaining fields
    const table_properties = {};
    Object.keys(rest).forEach((key) => {
      if (
        key !== "joining_amount" &&
        key !== "max_players" // Exclude static fields
      ) {
        table_properties[key] = rest[key];
      }
    });

    // Final payload

    const payload = {
    
      table_type,
      joining_amount: values.joining_amount,
      max_players: values.max_players || filldata.max_players, // Handle optional field
      table_properties, // Dynamic properties
    }
   
    const point_payload = {
    
      table_type,
      joining_amount: values.joining_amount,
      table_properties, // Dynamic properties
    }

    const deal_payload = {
    
      table_type,
      joining_amount: values.joining_amount,
      table_properties, // Dynamic properties

    }

 

    Rummysettings_edit(  table_type == "Point" ? point_payload : table_type == "Deal" ? deal_payload : payload , `${filldata._id}`)
      .then((response) => {
        if (response.status) {
          message.success({ content: response.message });
          apiCall();
          setIsModalVisible(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [formFields, setFormFields] = useState([]);
  const [selectedTableType, setSelectedTableType] = useState(null);

  const tableOptions = [
    { label: "Point", value: "Point" },
    { label: "Deal", value: "Deal" },
    { label: "Pool", value: "Pool" },
  ];



  // Handle table type selection
  const handleTableTypeChange = (tableType) => {
    let dynamicFields = [];

    // Define fields based on table type
    switch (tableType) {
      case "Deal":
        dynamicFields = [
          {
            name: "joining_amount",
            label: "Joining Amount",
            type: "number",
            rules: [
              { required: true, message: "Please enter Joining Amount" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value <= 10) {
                    return Promise.reject(
                      new Error("Joining Amount must be greater than 10")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
          {
            name: "no_of_rounds",
            label: "Number of Rounds",
            type: "dropdown",
            options: [2, 3],
          },
        ];
        break;

      case "Point":
        dynamicFields = [
          {
            name: "point_value",
            label: "Point Value",
            type: "number",
            rules: [
              { required: true, message: "Please enter Joining Amount" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value > 5) {
                    return Promise.reject(
                      new Error("Joining Amount must be smaller than 5")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
        ];
        break;

      case "Pool":
        dynamicFields = [
          {
            name: "joining_amount",
            label: "Joining Amount",
            type: "number",
            rules: [
              { required: true, message: "Please enter Joining Amount" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value <= 10) {
                    return Promise.reject(
                      new Error("Joining Amount must be greater than 10")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
          {
            name: "total_points",
            label: "Total Points",
            type: "dropdown",
            options: [101, 201],
          },
          {
            name: "max_players",
            label: "Max Players",
            type: "dropdown",
            options: [2, 6],
          },
        ];
        break;

      default:
        dynamicFields = []; // No fields for undefined table type
    }

    // Set form fields dynamically
    setFormFields(dynamicFields);

    // Reset form fields and prefill based on table type
    form.resetFields();
    form.setFieldsValue({ table_type: tableType }); // Set the table_type in the form
  };


  // On Submit
  const handleFinish = (values) => {
    const payload = {
      table_type: selectedTableType,
      ...values,
    };
    console.log("Payload:", payload);
    message.success("Settings Submitted Successfully!");
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        from === "add" ? createSettings(values) : editSettings(values);

        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title={from === "add" ? "Add Settings " : "Edit Settings"}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{}}
      >
        {/* <Form.Item
  key="joining_amount"
  label="Joining Amount"
  name="joining_amount"
  rules={[{ required: true, message: "Please enter Joining Amount" }]}>
  <InputNumber min={1} style={{ width: "100%" }} />
</Form.Item> */}


        {/* Dropdown for Table Type */}
        <Form.Item
          label="Table Type"
          name="table_type"
          rules={[{ required: true, message: "Please select a table type" }]}
        >
          <Select
            placeholder="Select Table Type"
            onChange={(value) => {
              handleTableTypeChange(value);
              form.setFieldsValue({ table_type: value });
            }}
          >
            {tableOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Dynamic Form Fields */}

        {formFields.map((field) => (
          <Form.Item
            key={field.name}
            label={field.label}
            name={field.name}
            rules={field.rules || [{ required: true, message: `Please enter ${field.label}` }]}
          >
            {field.type === "number" ? (
              table_type == "point" ? 
              <InputNumber min={1} max={5} style={{ width: "100%" }} />
              :
              <InputNumber min={1} style={{ width: "100%" }} />
            ) : field.type === "dropdown" ? (
              <Select placeholder={`Select ${field.label}`}>
                {field.options.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            ) : null}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default RummyAddSettings;
