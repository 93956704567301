import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Pagination, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { setHeading, setselectedTab } from "../../../../Store/B2bslice";
import { getRummyTableList } from "../../../../ApiServices/Apifun";
import { ExtractDate, ExtractTime } from "../../../../Utils";
import { BsEye } from "react-icons/bs";

const RummyTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [TableData, setTableData] = useState([]);
  const columns = [
    {
      title: "Max. Blind",
      dataIndex: "max_blind",
      key: "max_blind",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
    {
      title: "Number of players",
      dataIndex: "no_of_players",
      key: "no_of_players",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
    {
      title: "Max. players",
      dataIndex: "max_players",
      key: "max_players",
      render: (text) => <p className="w-[100px]">{text}</p>,
    },
    {
      title: "Boot Amount",
      dataIndex: "boot_amount",
      key: "boot_amount",
      render: (text) => <p className="w-[100px]">{text} Rs.</p>,
    },
    {
      title: "Max. Plot Limit",
      dataIndex: "max_pot_limit",
      key: "max_pot_limit",
      render: (text) => <p className="w-[100px]">{text} Rs.</p>,
    },
    {
      title: "Max. Bet Amount",
      dataIndex: "max_bet_amount",
      key: "max_bet_amount",
      render: (text) => <p className="w-[100px]">{text} Rs.</p>,
    },
    {
      title: "Status ",
      dataIndex: "is_joinable",
      key: "is_joinable",
      render: (text, record) => (
        <p className="w-[100px] bg-primary text-white rounded-md">
          {record.is_joinable === true ? "Joinable" : "Not Joinable"}
        </p>
      ),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <p className="w-[100px]">
          {ExtractDate(text)}.{ExtractTime(text)}
        </p>
      ),
    },
    {
      title: "Table Rounds",
      dataIndex: "rounds",
      key: "rounds",
      render: (text, record) => (
        <p
          onClick={() =>
            navigate("/rummy/tableData/table_rounds", {
              state: { id: record?._id },
            })
          }
          className="text-primary font-bold cursor-pointer"
        >
          <BsEye />
        </p>
      ),
    },
  ];
  const getTableData = () => {
    setLoading(true);
    getRummyTableList(`?page=${current}&limit=10`)
      .then((res) => {
        if (res.status) {
          setTableData(res.data);
          setCurrent(res.pagination.currentPage);
          setTotalPages(res.pagination.totalPages);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("11.1"));
    dispatch(setHeading("Rummy Table Data"));
    getTableData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  return (
    <div className="mt-4">
      <Table
        className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
        columns={columns}
        loading={loading}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            className: "cursor-pointer text-[12px]",
          };
        }}
        dataSource={TableData}
      />
      <div className="mt-2 mb-8 flex justify-end items-center">
        <Pagination
          showQuickJumper={false}
          defaultCurrent={current}
          pageSize={10}
          showSizeChanger={false}
          current={current}
          total={10 * totalPages}
          onChange={(page, pageString) => setCurrent(page)}
        />
      </div>
    </div>
  );
};

export default RummyTable;
