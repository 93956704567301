export const Authendpoint = {
  Login: "admin/login",
  logout: "admin/log-out",
};
export const Globalendpoints = {
  dashboardCount: "admin/fetch-dashboard-count",
  graphData: "admin/fetch-graph-data",
  createGame: "admin/create-game",
  getGameList: "admin/fetch-games",
  updateGame: "admin/update-games/",
  addBankDetail: "admin/add-bank-details",
  updateBankDetail: "admin/update-bank-details",
  fetchBankDetail: "admin/fetch-bank-details",
  getPaymentSetting: "admin/get-payment-setting",
  updatePaymentSetting: "admin/update-payment-setting",
  fetchFundReq: "admin/fetch-fund-request",
  fundReqAction: "admin/update-fund-request",
  fetchWithdrawalReq: "admin/fetch-withdrawal",
  updateWithdrawalReq: "admin/update-withdraw-request",
  currentRoomStats: "",
  roomDetails: "",
  chooseWinner: "",
  betDetailById: "",

  //aviator game

  aviatorSetting: "aviator/admin/fetch-settings",
  aviatorPlanHandler: "aviator/admin/plane-handler",
  aviatorRooms: "aviator/admin/fetch-aviator-room-records",
  aviatorBettingRecord: "aviator/admin/fetchBettingRecords",
  aviatorSetRange: "aviator/admin/set-plane-range-manually",
  aviatorCrashPlane: "aviator/admin/crashPlane",
  aviatorCurrentPlane: "aviator/admin/fetch-current-plane",

  //mines game

  mineSettings: "mines/admin/game-setting",
  minesUpdateSetting: "mines/admin/update-game-setting",
  minesBettingRecord: "mines/admin/fetch-betting-records",
  minesLatestBettingRecord: "mines/admin/fetch-latest-betting-records",

  //plinko games

  plinkoSettings: "plinko/admin/game-setting",
  plinkoUpdateSetting: "plinko/admin/update-game-setting",
  plinkoBettingRecord: "plinko/admin/fetch-betting-records",
  plinkoLatesyBettingRecord: "plinko/admin/fetch-latest-betting-records",

  userList: "admin/fetch-user-list",
  updateUser: "admin/update-user-status",
  userBettings: "admin/fetch-user-bettings",
  walletTxn: "admin/fetch-user-wallet-tranx",
  colorOption: "",
  changePassword: "admin/change-password",

  //comissionSetting
  fetchComissionSetting: "admin/fetch-commission-setting",
  updateReferalComm: "admin/update-referal-setting",
  //teenpatti
  getTeenPattiUserList: `admin/adminUser/getAllUsers`,
  modifyStatus: `admin/adminUser/ActiveDeActiveUser?userId=`,
  getTeenPattiTableList: `/admin/teenpatti/adminTable/getActiveTableList`,
  getTableRoundsList: `/admin/teenpatti/adminTable/getRounds`,
  getTeenPattiSettingsList: `/admin/teenpatti/adminSettings/getTableSetting`,
  deleteSettings: `/admin/teenpatti/adminSettings/deleteSetting?id=`,
  settings_edit: `/admin/teenpatti/adminSettings/updateTableSetting?id=`,
  addSettings: `/admin/teenpatti/adminSettings/addTableSetting`,
  getTeenPattiUserRechargeHistoryList: `admin/adminUser/UserRechargeHistory?page=`,

  //Rummy
  getRummyUserList: `admin/adminUser/getAllUsers`,
  modifyStatus: `admin/adminUser/ActiveDeActiveUser?userId=`,
  getRummyTableList: `/admin/rummy/table/getActiveTableList`,
  getTableRummyRoundsList: `/admin/rummy/table/getRounds`,
  getRummySettingsList: `/admin/rummy/tableSettings/getTableSetting`,
  rummyDeleteSettings: `/admin/rummy/tableSettings/deleteSetting?id=`,
  Rummysettings_edit: `/admin/rummy/tableSettings/updateTableSetting?id=`,
  RummyaddSettings: `/admin/rummy/tableSettings/addTableSetting`,
  tournamentsaddSettings: `/admin/rummy/tournament/createTournament`,
  Tournamentsettings_edit: `/admin/rummy/tournament/updateTournament`,
  getRummyUserRechargeHistoryList: `admin/adminUser/UserRechargeHistory?page=`,
  getTournamentsList: `/admin/rummy/tournament/getList`,
  rummyDeleteTournaments: `/admin/rummy/tournament/deleteTournament?id=`,

  //Ludo
  getLudoUserHistory: `/ludo/admin/fetch`,
  getLudoDetails: `/ludo/admin/fetch-ludo-cut`,
  ludo_edit: `/ludo/admin/update`,
};
